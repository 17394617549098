<template>
  <div
    id="brower-bar-charts"
    :style="{width: '100%', height: '360px',margin:'auto'}"
  />
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, onMounted, watchEffect } from 'vue'

export default defineComponent({
  name: 'BrowerBarCharts',
  props: ['data'],
  setup (props) {
    const { proxy }: any = getCurrentInstance()
    const x = computed<number[]>(() => {
      const arr: number[] = []
      props.data.forEach((item: any) => {
        if (item.value !== 0) {
          arr.push(item.name)
        }
      })
      return arr
    })
    const y = computed<number[]>(() => {
      const arr: number[] = []
      props.data.forEach((item: any) => {
        if (item.value !== 0) {
          arr.push(item.value)
        }
      })
      return arr
    })
    onMounted(() => {
      const browerBarCharts = proxy.$echarts.init(document.getElementById('brower-bar-charts'))
      const render = () => {
        browerBarCharts.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            data: x.value
          },
          yAxis: {},
          series: [
            {
              name: '省份',
              type: 'bar',
              data: y.value,
              barWidth: 30,
              itemStyle: {
                normal: {
                  color: (params: any) => {
                    const colorList = [
                      '#C1232B', '#B5C334', '#FCCE10', '#E87C25', '#27727B',
                      '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD',
                      '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0'
                    ]
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        })
      }
      watchEffect(() => {
        render()
      })
      setTimeout(() => {
        browerBarCharts.resize()
        window.addEventListener('resize', () => browerBarCharts.resize())
      }, 200)
    })
  }
})
</script>
